import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../pages/About";
import Home from "../pages/Home";

const Contact = () =>
  <p style={{ textAlign: "center" }}>
    Get in touch at{" "}
    <a href="mailto:happysingingkids@gmail.com">
      happysingingkids@gmail.com
    </a>
    ,
    <br /> we'd love to hear from you!
  </p>

export default (
  <Routes>
    <Route path="/(cancel)?" element={ <Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={ <Contact /> } />
  </Routes>
);
