import React from "react";
import hero from "../../assets/celtic-spirit.jpg";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <div className={styles.Header}>
      <div className={styles.Gradient}></div>
      <div className={styles.Content}>
        <h1>Celtic Spirit</h1>
        <img className={styles.Hero} src={hero} alt="Celtic Spirit" />
      </div>
    </div>
  )
};

export default Header;
